const hoverCardPopperConfig = () => {
  return {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5]
        }
      }
    ]
  };
};

/**
 * Hover Card Item Type.
 */
export enum HoverCardItemType {
  USER = 'User',
  FORUM_REPLY_MESSAGE = 'ForumReplyMessage',
  TKB_REPLY_MESSAGE = 'TkbReplyMessage',
  BLOG_REPLY_MESSAGE = 'BlogReplyMessage',
  IDEA_MESSAGE = 'IdeaMessage',
  IDEA_TOPIC_MESSAGE = 'IdeaTopicMessage',
  FORUM_TOPIC_MESSAGE = 'ForumTopicMessage',
  TKB_TOPIC_MESSAGE = 'TkbTopicMessage',
  BLOG_TOPIC_MESSAGE = 'BlogTopicMessage',
  OCCASION_TOPIC_MESSAGE = 'OccasionTopicMessage',
  ACCEPTED_SOLUTION_MESSAGE = 'AcceptedSolutionMessage',
  BOARD = 'Board',
  CATEGORY = 'Category',
  GROUP_HUB = 'GroupHub',
  NODE = 'Node',
  FORUM = 'Forum',
  BLOG = 'Blog',
  TKB = 'Tkb',
  IDEA = 'Idea',
  OCCASION = 'Occasion'
}

const userHoverCardDefaultProps = {
  useAvatar: true,
  useLogin: true,
  useRank: true,
  useTitle: true,
  useMessagesCount: true,
  useBiography: true,
  useRegistrationData: true,
  useBadgesList: true,
  useFollowersCount: true,
  useKudosCount: true,
  useSolutionCount: true
};

const nodeHoverCardDefaultProps = {
  useNodeParent: true,
  useNodeLatestActivityTime: true,
  useMembershipInformation: true
};

export { hoverCardPopperConfig, userHoverCardDefaultProps, nodeHoverCardDefaultProps };
