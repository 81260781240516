import React from 'react';
import type { EditedQuiltWrapper } from '../../pageeditor/PageBuilder/types';
import type {
  QuiltWrapperSectionType,
  QuiltWrapperWidget
} from '../../pageeditor/QuiltWrapperRenderer/types';
export type QuiltWrapperEditorSelection = QuiltWrapperSectionType | QuiltWrapperWidget;

export interface QuiltWrapperEditorContextInterface {
  /**
   * The quilt wrapper currently being rendered. This comes from local storage if present or the quilt wrapper LRU cache
   */
  quiltWrapper: EditedQuiltWrapper;
  /**
   * The currently selected item in PageEditor
   */
  selection: QuiltWrapperEditorSelection;
  /**
   * Sets the current user selection in context
   *
   * @param id the selection
   */
  setSelection: (id: QuiltWrapperEditorSelection) => void;
  /**
   * Adds a selectable element to a ref object so that it may be accessed anywhere in context
   *
   * @param id the id of the selectable element
   * @param element the DOM element
   */
  addSelectableElement: (id: QuiltWrapperEditorSelection, element: HTMLElement) => void;
  /**
   * Scrolls to the selectable element with the matching id
   * @param id the id of the selection to scroll to
   */
  scrollToSelection: (id: QuiltWrapperEditorSelection) => void;
  /**
   * Adds or removes a highlighted border for the selection
   *
   * @param id the id of the selection to highlight
   * @param add whether to add or remove the highlight
   * @param danger whether to apply the danger color (removal of selections)
   */
  highlightSelection: (id: QuiltWrapperEditorSelection, add: boolean, danger?: boolean) => void;
  /**
   * Whether to show edit controls on the page editor
   */
  showEditControls: boolean;
}

/**
 * Context API used to store the quilt wrapper and user selections made while modifying a wrapper during a page editor session
 *
 * @author Jonathan Bridges
 */
const PageEditorQuiltWrapperContext = React.createContext<QuiltWrapperEditorContextInterface>({
  quiltWrapper: null,
  selection: null,
  setSelection: (): void => null,
  addSelectableElement: (): void => null,
  scrollToSelection: (): void => null,
  highlightSelection: (): void => null,
  showEditControls: false
});
export default PageEditorQuiltWrapperContext;
