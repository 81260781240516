import { BoundaryPaddingLocation } from '@aurora/shared-client/components/form/enums';
import { getStyle } from '@aurora/shared-client/helpers/fonts/FontDefinitions';
import { parseColorFromCssBorder } from '@aurora/shared-client/helpers/styles/ColorHelper';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import {
  extractCssVariable,
  isWrappedCssVariable
} from '@aurora/shared-client/helpers/styles/CssVariableHelper';
import type { QuiltComponent } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize,
  FontStyle,
  NavbarPosition
} from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  QuiltWrapperFragment,
  ThemeResultFragment
} from '@aurora/shared-generated/types/graphql-types';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import type { Tenant } from '@aurora/shared-types/tenant';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import { linkWithChildrenPropsToLinkProps } from '@aurora/shared-utils/helpers/props/NavbarLinkPropsHelper';
import { getThemeAssetURL } from '@aurora/shared-utils/helpers/theme/ThemeAssetHelper';
import type {
  NavbarStyleProps,
  NavbarVariantProps,
  NavbarWidgetProps
} from '../../components/community/Navbar/types';
import {
  LanguagePickerStyle,
  NavbarHighlightStyle,
  NavbarLinkAlignment
} from '../../components/community/Navbar/types';
import type { QuiltWrapperWidgetLocation } from '../../components/context/QuiltWrapperWidgetContext/QuiltWrapperWidgetLocationContext';
import { AssetFeature, getQuiltAssetURL } from '../util/AssetHelper';

export interface PageHeaderNavbarProps {
  /**
   * Whether the Navbar is transparent
   */
  useTransparentNavbar?: boolean;
}

/**
 * Named to contrast with NavbarProps GraphQL type.
 * Used as input to Navbar
 */
export interface LocalNavbarProps {
  /**
   * Whether to show the Nav items such as Login Link and Spotlight Search.
   */
  useNavMenuItems?: boolean;

  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;

  /**
   * The properties which may be changed using the Page Editor form
   */
  navbarWidgetProps: NavbarWidgetProps;

  /**
   * The properties which may be changed using the Page Editor form
   */
  navbarVariantProps: NavbarVariantProps;
}

function isZeroOrNone(property: string) {
  return property === '0' || property === 'none';
}

/**
 * The Navbar highlight style is not stored directly in the theme, but derived from the selection
 * and stored in 3 separate fields. We can determine the highlight style from 2 of them.
 */
function calculateNavbarHighlightStyle(
  borderBottomHover: string,
  bgHoverColor: string
): NavbarHighlightStyle {
  if (!isZeroOrNone(borderBottomHover) && bgHoverColor === 'transparent') {
    return NavbarHighlightStyle.UNDERLINE;
  }

  if (isZeroOrNone(borderBottomHover) && bgHoverColor !== 'transparent') {
    return NavbarHighlightStyle.BACKGROUND;
  }

  if (isZeroOrNone(borderBottomHover) && bgHoverColor === 'transparent') {
    return NavbarHighlightStyle.COLOR;
  }

  return NavbarHighlightStyle.CUSTOM;
}

/** Determine the selected option based on the stored value */
function calculateNavbarLinkAlignment(linkJustifyContent: string) {
  switch (linkJustifyContent) {
    case 'flex-start': {
      return NavbarLinkAlignment.LEFT;
    }

    case 'center': {
      return NavbarLinkAlignment.CENTER;
    }

    case 'flex-end': {
      return NavbarLinkAlignment.RIGHT;
    }

    case 'space-between': {
      return NavbarLinkAlignment.SPACE_BETWEEN;
    }

    case 'space-around': {
      return NavbarLinkAlignment.SPACE_AROUND;
    }

    default: {
      return NavbarLinkAlignment.LEFT;
    }
  }
}

/**
 * The correct link highlight color to use is dependent on which highlight style was determined
 */
function calculateLinkHighlightColor(
  highlightStyle: NavbarHighlightStyle,
  hoverColor: string,
  bgHoverColor: string,
  linkTextBorderBottomHover: string
) {
  switch (highlightStyle) {
    case NavbarHighlightStyle.COLOR: {
      return hoverColor;
    }

    case NavbarHighlightStyle.UNDERLINE: {
      return parseColorFromCssBorder(linkTextBorderBottomHover);
    }

    case NavbarHighlightStyle.BACKGROUND: {
      return bgHoverColor;
    }

    case NavbarHighlightStyle.CUSTOM: {
      return hoverColor;
    }
  }
}

/**
 * Helper function for determining the correct props to pass into navbar
 * @param widgetProps the navbar widget props
 */
export default function toNavbarProps(widgetProps: NavbarWidgetProps): LocalNavbarProps {
  const {
    style,
    links,
    languagePickerStyle,
    useLabelLanguagePicker,
    useIconLanguagePicker,
    showLanguagePicker,
    showRegisterLink,
    showSearchIcon,
    showUserName
  } = widgetProps;
  const {
    background,
    controllerHighlightColor,
    controllerIconColor,
    linkColor,
    linkFontSize,
    linkFontStyle,
    linkFontWeight,
    brandLogoHeight,
    borderBottom,
    boxShadow,
    paddingBottom,
    paddingTop,
    position,
    backgroundOpacity,
    linkTextBorderBottomHover,
    linkBgHoverColor,
    linkHoverColor,
    linkJustifyContent
  } = style ?? {};

  const { mainLinks, sideLinks } = links ?? {};

  const {
    color: backgroundColor,
    imageAssetName,
    imageLastModified,
    size: backgroundSize,
    position: backgroundPosition,
    repeat: backgroundRepeat
  } = background ?? {};

  const realFontSize = isWrappedCssVariable(linkFontSize)
    ? (extractCssVariable(linkFontSize, '14px') as string)
    : linkFontSize;

  const realFontStyle = isWrappedCssVariable(linkFontStyle)
    ? (extractCssVariable(linkFontStyle, FontStyle.Normal) as FontStyle)
    : getAsEnum(linkFontStyle, FontStyle, FontStyle.Normal);

  const realFontWeight = isWrappedCssVariable(linkFontWeight)
    ? (extractCssVariable(linkFontWeight, '400') as string)
    : linkFontWeight ?? '400';

  const { name: fontStyleName } = getStyle(realFontWeight, realFontStyle);

  const highlightStyle = calculateNavbarHighlightStyle(linkTextBorderBottomHover, linkBgHoverColor);

  const linkHighlightColor = calculateLinkHighlightColor(
    highlightStyle,
    linkHoverColor,
    linkBgHoverColor,
    linkTextBorderBottomHover
  );

  const linkAlignment = calculateNavbarLinkAlignment(linkJustifyContent);

  return {
    navbarVariantProps: {
      highlightStyle,
      linkTextColor: linkColor,
      linkHighlightColor,
      controllerIconColor: controllerIconColor,
      controllerHighlightColor: controllerHighlightColor,
      linkFontSize: Number.parseInt(realFontSize, 10),
      linkFontStyle: fontStyleName,
      linkAlignment,
      logoHeight: Number.parseInt(brandLogoHeight, 10),
      languagePickerStyle: languagePickerStyle ?? LanguagePickerStyle.ICON_LABEL,
      useIconLanguagePicker: useIconLanguagePicker ?? true,
      useLabelLanguagePicker: useLabelLanguagePicker ?? true,
      showLanguagePicker: showLanguagePicker ?? true,
      showSearchIcon: showSearchIcon ?? true,
      showRegisterLink: showRegisterLink ?? true,
      showUsername: showUserName ?? false,
      backgroundColor: backgroundColor ?? CommonColorCssVariables.WHITE,
      backgroundImage: {
        assetName: imageAssetName,
        lastModified: imageLastModified,
        backgroundSize: (backgroundSize as BackgroundSize) ?? BackgroundSize.Cover,
        backgroundPosition:
          (backgroundPosition as BackgroundPosition) ?? BackgroundPosition.CenterCenter,
        backgroundRepeat: (backgroundRepeat as BackgroundRepeat) ?? BackgroundRepeat.NoRepeat
      },
      backgroundOpacity,
      visualEffects: {
        useStickyPosition: position === NavbarPosition.Fixed,
        showBottomBorder: !isZeroOrNone(borderBottom),
        showBottomShadow: !isZeroOrNone(boxShadow)
      },
      boundaryPadding: {
        [BoundaryPaddingLocation.TOP]: Number.parseInt(paddingTop, 10),
        [BoundaryPaddingLocation.BOTTOM]: Number.parseInt(paddingBottom, 10)
      },
      mainLinks: linkWithChildrenPropsToLinkProps(mainLinks),
      sideLinks: linkWithChildrenPropsToLinkProps(sideLinks)
    },
    navbarWidgetProps: widgetProps
  };
}

/**
 * Reformats an enum value into a CSS prop format.
 *
 * @param value the value
 */
function reformatFromEnum(value: string | number | null | undefined): string | undefined {
  return value ? value.toString().replaceAll('_', '-').toLowerCase() : undefined;
}

export function getCssStyleFromProps(style: NavbarStyleProps): CSSPropertiesWithVars {
  const {
    background,
    backgroundOpacity,
    paddingTop,
    paddingBottom,
    borderBottom,
    boxShadow,
    brandMarginRight,
    brandMarginRightSm,
    brandLogoHeight,
    linkGap,
    linkJustifyContent,
    linkPaddingY,
    linkPaddingX,
    linkDropdownPaddingY,
    linkDropdownPaddingX,
    linkColor,
    linkBorder,
    linkHoverColor,
    linkFontSize,
    linkFontStyle,
    linkFontWeight,
    linkTextTransform,
    linkLetterSpacing,
    linkBorderRadius,
    linkBgColor,
    linkBgHoverColor,
    linkBorderHover,
    linkBoxShadowHover,
    linkTextBorderBottom,
    linkBoxShadow,
    linkTextBorderBottomHover,
    dropdownPaddingTop,
    dropdownPaddingBottom,
    collapseMenuMarginLeft,
    controllerBorderRadius,
    dropdownDividerMarginTop,
    dropdownDividerMarginBottom,
    dropdownMenuOffset,
    hamburgerBgHoverColor,
    hamburgerBorder,
    hamburgerBorderHover,
    hamburgerColor,
    hamburgerHoverColor,
    dropdownPaddingX,
    hamburgerBgColor,
    controllerHighlightColor,
    controllerHighlightTextColor,
    controllerIconColor,
    controllerIconHoverColor,
    controllerTextColor,
    controllerTextHoverColor,
    dropdownBorderColor,
    controllerBgHoverColor,
    collapseMenuDividerOpacity,
    collapseMenuDividerBg
  } = style;

  const realFontStyle = isWrappedCssVariable(linkFontStyle)
    ? (extractCssVariable(linkFontStyle, FontStyle.Normal) as FontStyle)
    : getAsEnum(linkFontStyle, FontStyle, FontStyle.Normal);

  const realFontWeight = isWrappedCssVariable(linkFontWeight)
    ? (extractCssVariable(linkFontWeight, '400') as string)
    : linkFontWeight ?? '400';

  const { style: finalLinkFontStyle, weight: finalLinkFontWeight } = getStyle(
    realFontWeight,
    realFontStyle
  );

  return {
    '--lia-nav-bg-color': background?.color,
    '--lia-nav-bg-opacity': backgroundOpacity?.toString(),
    '--lia-nav-pt': paddingTop,
    '--lia-nav-pb': paddingBottom,
    '--lia-nav-border-bottom': borderBottom,
    '--lia-nav-box-shadow': boxShadow,
    '--lia-nav-brand-mr': brandMarginRight,
    '--lia-nav-brand-mr-sm': brandMarginRightSm,
    '--lia-nav-brand-logo-height': brandLogoHeight,
    '--lia-nav-link-gap': linkGap,
    '--lia-nav-link-justify-content': linkJustifyContent,
    '--lia-nav-link-py': linkPaddingY,
    '--lia-nav-link-px': linkPaddingX,
    '--lia-nav-link-dropdown-py': linkDropdownPaddingY,
    '--lia-nav-link-dropdown-px': linkDropdownPaddingX,
    '--lia-nav-link-color': linkColor,
    '--lia-nav-link-hover-color': linkHoverColor,
    '--lia-nav-link-font-size': linkFontSize,
    '--lia-nav-link-font-style': finalLinkFontStyle,
    '--lia-nav-link-font-weight': finalLinkFontWeight,
    '--lia-nav-link-text-transform': reformatFromEnum(linkTextTransform),
    '--lia-nav-link-letter-spacing': linkLetterSpacing,
    '--lia-nav-link-border-radius': linkBorderRadius,
    '--lia-nav-link-bg-color': linkBgColor,
    '--lia-nav-link-bg-hover-color': linkBgHoverColor,
    '--lia-nav-link-border': linkBorder,
    '--lia-nav-link-border-hover': linkBorderHover,
    '--lia-nav-link-box-shadow': linkBoxShadow,
    '--lia-nav-link-box-shadow-hover': linkBoxShadowHover,
    '--lia-nav-link-text-border-bottom': linkTextBorderBottom,
    '--lia-nav-link-text-border-bottom-hover': linkTextBorderBottomHover,
    '--lia-nav-dropdown-pt': dropdownPaddingTop,
    '--lia-nav-dropdown-pb': dropdownPaddingBottom,
    '--lia-nav-dropdown-px': dropdownPaddingX,
    '--lia-nav-dropdown-menu-offset': dropdownMenuOffset,
    '--lia-nav-dropdown-divider-mt': dropdownDividerMarginTop,
    '--lia-nav-dropdown-divider-mb': dropdownDividerMarginBottom,
    '--lia-nav-dropdown-border-color': dropdownBorderColor,
    '--lia-nav-controller-bg-hover-color': controllerBgHoverColor,
    '--lia-nav-controller-icon-color': controllerIconColor,
    '--lia-nav-controller-icon-hover-color': controllerIconHoverColor,
    '--lia-nav-controller-icon-highlight': controllerHighlightColor,
    '--lia-nav-controller-icon-highlight-text': controllerHighlightTextColor,
    '--lia-nav-controller-text-color': controllerTextColor,
    '--lia-nav-controller-text-hover-color': controllerTextHoverColor,
    '--lia-nav-controller-border-radius': controllerBorderRadius,
    '--lia-nav-hamburger-color': hamburgerColor,
    '--lia-nav-hamburger-hover-color': hamburgerHoverColor,
    '--lia-nav-hamburger-bg-color': hamburgerBgColor,
    '--lia-nav-hamburger-bg-hover-color': hamburgerBgHoverColor,
    '--lia-nav-hamburger-border': hamburgerBorder,
    '--lia-nav-hamburger-border-hover': hamburgerBorderHover,
    '--lia-nav-collapse-menu-ml': collapseMenuMarginLeft,
    '--lia-nav-collapse-menu-divider-bg': collapseMenuDividerBg,
    '--lia-nav-collapse-menu-divider-opacity': collapseMenuDividerOpacity?.toString()
  };
}

/**
 * Builds background image url to use quilt wrapper asset bg image url or theme asset bg image url
 *
 * @param quiltWrapper the quilt wrapper
 * @param tenant the tenant
 * @param theme the theme in use
 * @param widgetLocation the widget location
 * @param assetName the final asset name to use
 * @param lastModified the last modified background image timestamp
 * @param branchName if not on the main branch, the branch name
 */
export function getBackgroundImageUrl(
  quiltWrapper: QuiltWrapperFragment,
  tenant: Tenant,
  theme: ThemeResultFragment,
  widgetLocation: QuiltWrapperWidgetLocation,
  assetName: string,
  lastModified: string,
  branchName?: string
): string {
  const navBarWidget: QuiltComponent = widgetLocation
    ? quiltWrapper[widgetLocation.sectionId].items[widgetLocation.widgetIdx]
    : null;
  const navBarWidgetProps: NavbarWidgetProps = navBarWidget?.props;
  const isBackgroundImageOverride = navBarWidgetProps?.style?.background?.imageAssetName;
  const { navbar: navbarDefaultProps } = theme;

  const isImageFromTheme = navbarDefaultProps?.background?.imageAssetName === assetName;

  return !!isBackgroundImageOverride && !isImageFromTheme
    ? getQuiltAssetURL(
        tenant,
        quiltWrapper.id,
        assetName,
        AssetFeature.QUILT_WRAPPERS,
        lastModified,
        branchName
      )
    : getThemeAssetURL(tenant, theme.id, assetName, lastModified, null, branchName);
}
