import type { ThemeEditorResult } from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorResult';
import type { FontDefinition } from '@aurora/shared-client/helpers/fonts/FontDefinitions';
import FontDefinitions, {
  FontClassification,
  getStyle
} from '@aurora/shared-client/helpers/fonts/FontDefinitions';
import RegularStyle from '@aurora/shared-client/helpers/fonts/RegularStyle';
import { FontStyleName } from '@aurora/shared-client/helpers/fonts/types';
import { parseFontFamily } from '@aurora/shared-client/helpers/styles/FontHelper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';

export type FontStylePossibleValues = { key: string; value: FontStyleName; label?: string };

/**
 * Gets the list of valid font styles for a specified font family as
 * Select values. Select values are intended to be used in a Select form field.
 *
 * @param fontDefinitions the font definitions
 * @param fontFamily the font family
 */
export function getFontStylesAsSelectValues(
  fontDefinitions: FontDefinition[],
  fontFamily: string
): FontStylePossibleValues[] {
  // Find a font with the specified family and "custom" classification
  let fontDefinition = fontDefinitions.find(
    font => font.family === fontFamily && font.classification === FontClassification.CUSTOM
  );

  // If not found, find a font with the specified family
  if (!fontDefinition) {
    fontDefinition = fontDefinitions.find(font => font.family === fontFamily);
  }

  // If no font definition was found, attempt to locate one based on a partial
  // match of the font family.
  if (!fontDefinition) {
    const fontFamilyParts = parseFontFamily(fontFamily);
    fontDefinition = fontDefinitions.find(font => {
      return fontFamilyParts.some(fontFamilyPart =>
        parseFontFamily(font.family).includes(fontFamilyPart)
      );
    });
  }

  const styles = fontDefinition?.styles ?? [new RegularStyle()];
  return styles.map(style => ({ key: style.name.toString(), value: style.name }));
}

/**
 * Gets a list of custom font definitions for a saved theme
 * @param theme The saved theme
 */
export function getFontCustomValues(theme: ThemeResultFragment): FontDefinition[] {
  const customValues: FontDefinition[] = theme.typography.customFonts?.map(customFont => {
    return {
      family: customFont.name,
      classification: FontClassification.CUSTOM,
      source: customFont.source,
      styles: customFont.styles.map(style => getStyle(style.weight, style.style)),
      assetNames: customFont.assetNames
    };
  });
  return customValues ?? [];
}

/**
 * Gets a list of valid font styles for a specified font family and saved theme
 * @param theme The saved theme
 * @param fontFamily the font family
 */
export function getFontStyles(
  theme: ThemeResultFragment,
  fontFamily: string = theme.typography.fontFamilyBase
): FontStylePossibleValues[] {
  const customValues = getFontCustomValues(theme);
  const availableFonts = [...FontDefinitions.fonts, ...customValues];

  return getFontStylesAsSelectValues(availableFonts, fontFamily);
}

/**
 * Gets a list of valid font styles for a specified font family and pending Theme Editor values
 * @param theme The pending theme editor values
 * @param fontFamily The font family
 */
export function getFontStylesFromThemeEditorResult(
  theme: ThemeEditorResult,
  fontFamily: string = theme.fonts.bodyText.value.family as string
): FontStylePossibleValues[] {
  const customValues = theme.fonts.custom;
  const availableFonts = [...FontDefinitions.fonts, ...customValues];

  return getFontStylesAsSelectValues(availableFonts, fontFamily);
}

/**
 * Get a font style, as a select value, from a list of possible font style values.
 * Gracefully falls back to returning either the regular font style if in the list of
 * possible values, otherwise uses the first possible value if no matches, and finally
 * falls back to the regular font style if no possible values are passed.
 *
 * @param fontStyleName the font style name
 * @param fontStylePossibleValues the font style possible values
 */
export function chooseSelectValue(
  fontStyleName: FontStyleName,
  fontStylePossibleValues: FontStylePossibleValues[]
): FontStyleName {
  // Best case -- we can use the same value and match it up
  if (fontStylePossibleValues.some(subValue => subValue.value === fontStyleName)) {
    return fontStyleName;
  }

  // If that fails, we attempt to default to the 'Regular' style
  if (fontStylePossibleValues.some(subValue => subValue.value === FontStyleName.REGULAR)) {
    return FontStyleName.REGULAR;
  }

  // If all else fails, default to the first available option
  return fontStylePossibleValues?.[0]?.value ?? FontStyleName.REGULAR;
}

/**
 * Get a font style, as a select value, from a list of possible font style values.
 * If the style is not available in the possible values, returns null.
 * This enables forms to use null as a default value when custom values are present.
 *
 * @param fontStyleName the font style name
 * @param fontStylePossibleValues the font style possible values
 */
export function getSelectValueForForm(
  fontStyleName: FontStyleName,
  fontStylePossibleValues: FontStylePossibleValues[]
): FontStyleName {
  return fontStylePossibleValues.some(subValue => subValue.value === fontStyleName)
    ? fontStyleName
    : null;
}
