import { useContext, useMemo } from 'react';
import type { SafeColorCallback } from '../helpers/styles/SafeColor';
import safeColor from '../helpers/styles/SafeColor';
import ThemeContext from './context/ThemeContext/ThemeContext';
import MultiThemeContext from './context/MultiThemeContext/MultiThemeContext';
import ThemeEditorContext from './community/ThemeEditorContext/ThemeEditorContext';
import type { ThemeEditorResult } from './community/ThemeEditorResult/ThemeEditorResult';
import { getCssRulesForTheme } from '../helpers/styles/ThemeRulesHelper';
import type { ColorCssVariableOrValue } from '@aurora/shared-types/styles';
import type Color from 'color';

/**
 * Maps the CSS variables to properties of the `ThemeEditorResult`.
 *
 * @param themeEditorResult the theme editor result; represents the theme currently being edited.
 */
export function createCssVariableMapForThemeInput(
  themeEditorResult: ThemeEditorResult
): Record<string, string> {
  const { colors } = themeEditorResult;
  const { value: primaryCommunity } = colors.primaryCommunity;
  const { value: alertMessages } = colors.alertMessages;

  const { primaryAccent, primaryText, pageBackground } = primaryCommunity;
  const { information, success, warning, danger } = alertMessages;

  return {
    '--lia-bs-primary': primaryAccent,
    '--lia-bs-body-color': primaryText,
    '--lia-bs-body-bg': pageBackground,
    '--lia-bs-info': information,
    '--lia-bs-success': success,
    '--lia-bs-warning': warning,
    '--lia-bs-danger': danger
  };
}

/**
 * Provides callback function that can be used to generate a `SafeColor` that
 * takes into account the current theme as well as the state of a theme that is being
 * edited.
 *
 * @param themeEditorResult the currently edited theme, this can be directly passed in the case
 * it cannot be derived from the `ThemeEditorContext`.
 *
 * @author Adam Ayres
 */
function useSafeColor(themeEditorResult: ThemeEditorResult = null): SafeColorCallback {
  const { theme } = useContext(ThemeContext);
  const { endUserTheme } = useContext(MultiThemeContext);
  const { currentState: themeEditorResultFromContext } = useContext(ThemeEditorContext);

  let variableMap: Record<string, string> = useMemo(() => {
    const finalTheme = endUserTheme ?? theme;
    return getCssRulesForTheme(finalTheme);
  }, [endUserTheme, theme]);

  const finalThemeEditorResult = themeEditorResult ?? themeEditorResultFromContext;

  if (finalThemeEditorResult) {
    const variableMapForEditingTheme = createCssVariableMapForThemeInput(finalThemeEditorResult);
    variableMap = {
      ...variableMap,
      ...variableMapForEditingTheme
    };
  }

  return (color: ColorCssVariableOrValue, fallback?: Color) =>
    safeColor(variableMap, color, fallback);
}

export default useSafeColor;
