import React from 'react';
import type { FontDefinition } from '../../../helpers/fonts/FontDefinitions';
import type { ThemeEditorResult } from '../ThemeEditorResult/ThemeEditorResult';
import type { ThemeEditorSection } from '../ThemeEditorSection/ThemeEditorSection';
import type ThemeStyleguideColorsSections from '../ThemeStyleguideColorsSections/ThemeStyleguideColorsSections';

export interface ThemeEditorContextProps {
  /**
   * All the available fonts -- FontDefinitions + Custom Fonts
   */
  availableFonts: FontDefinition[];

  /**
   * A common function to give to CloseIcons in left-side cards to return the state to the list
   */
  closeCard: () => void;

  /**
   * Exits the Theme Editor and returns to the main page.
   */
  closeThemeEditor: () => Promise<void>;

  /**
   * The currently selected item, if any
   */
  currentSection: ThemeEditorSection;

  /**
   * An object that keeps track of form changes
   */
  currentState: ThemeEditorResult;

  /**
   * Whether the theme has been modified.
   * It is the developer's responsibility to ensure this is set if adding new functionality --
   * use `handleUpdate` instead of `setUpdate` directly in ThemeEditor
   */
  isModified: boolean;

  /**
   * Returns Buttons to defaults
   */
  resetButtons(): void;

  /**
   * Update a color for the overall editor state
   */
  updateColor(
    sectionKey: keyof ThemeStyleguideColorsSections,
    colorKey: string,
    value: string
  ): void;

  /**
   * Remove a color for the overall editor state.
   * Does not require a sectionKey because only Additional Colors can be removed.
   */
  removeColor(colorKey: string): void;

  /**
   * Returns colors to defaults
   */
  resetColors(): void;

  /**
   * Returns elements to defaults
   */
  resetElements(): void;

  /**
   * Returns fonts to defaults
   */
  resetFonts(): void;

  /**
   * Changes the available fonts
   */
  setAvailableFonts(fonts: FontDefinition[]): void;
}

const ThemeEditorContext = React.createContext<ThemeEditorContextProps>({
  availableFonts: [],
  closeCard: () => {},
  closeThemeEditor: () => Promise.resolve(),
  currentSection: null,
  currentState: null,
  isModified: false,
  resetButtons: () => {},
  updateColor: () => {},
  removeColor: () => {},
  resetColors: () => {},
  resetElements: () => {},
  resetFonts: () => {},
  setAvailableFonts: () => {}
});

export default ThemeEditorContext;
