import { useQuery } from '@apollo/client/react/hooks';
import type { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import type { OperationVariables } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import type { DocumentNode } from 'graphql';
import useTracing from './useTracing';

/**
 * Wrapper around `useQuery` so that we can use it imperatively and add tracking info to the `context` property.
 *
 * Taken from https://github.com/apollographql/react-apollo/issues/3499#issuecomment-610649667
 * @example
 * const callQuery = useImperativeQuery(query, options)
 * const handleClick = async () => {
 *   const{ data, error } = await callQuery()
 * }
 *
 * @param module the module of the component
 * @param query the query
 * @param options the query options
 *
 * @author Amit Agrawal
 */
export default function useImperativeQueryWithTracing<
  TData = unknown,
  TVariables = OperationVariables
>(
  module: NodeModule | string,
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables>['refetch'] {
  const operationDefinition: string = getOperationName(query);
  const context = useTracing<TVariables, QueryHookOptions<TData, TVariables>>(
    module,
    operationDefinition,
    options
  );
  const { refetch } = useQuery<TData, TVariables>(query, {
    ...options,
    context,
    skip: true
  });

  return (queryVariables: TVariables) => {
    return refetch(queryVariables);
  };
}
